<template>
  <div>
    <video
      @ended="videoEnd()"
      ref="videoPlayer"
      class="video-js vjs-fluid vjs-default-skin vjs-big-play-centered"
    ></video>
  </div>
</template>
<style scoped lang="scss">
::v-deep {
  .video-js .vjs-tech,
  .video-js.vjs-fluid,
  .video-js.vjs-16-9,
  .video-js.vjs-4-3,
  .video-js.vjs-9-16,
  .video-js.vjs-1-1 {
    height: 100vh;
  }
  .vjs_video_3-dimensions.vjs-fluid {
    padding-top: unset;
  }
}
</style>
<script>
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },
  methods: {
    videoEnd() {
      console.log("ended");
      this.$router.push({ path: "questions" });
    },
  },
  mounted() {
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {}
    );
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
