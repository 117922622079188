<template>
  <div class="videoStyle">
    <video-player :options="videoOptions" />
    <button class="skip" @click="$router.push({ path: 'questions' })">
      Skip
    </button>
  </div>
</template>

<script>
import VideoPlayer from "../video";

export default {
  name: "VideoExample",
  components: {
    VideoPlayer,
  },
  data() {
    return {
      value: false,
      videoOptions: {
        autoplay: true,
        muted: true,
        sources: [
          {
            src: require("@/assets/img/intro.mp4"),
            type: "video/mp4",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./_landing-page.scss";
</style>
